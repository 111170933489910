// moduleUser.js

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "./firebaseConfig.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import $ from "jquery";

import { setupGoogleSignIn } from "./auth.js";
import { setupLogoutListener } from "./authUserLogout.js";
import { sendMessageToContentScript } from "./authPostMessage.js";

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);

// ------------------------------
// Helper: Get Test Email from URL
// ------------------------------
function getTestEmailFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  const testEmail = urlParams.get("email"); // Extract the email parameter from the URL
  return testEmail;
}

// ------------------------------
// FIRESTORE FETCHING
// ------------------------------

// Fetches data from Firestore
async function fetchUserGetData(email) {
  const folderDataRef = doc(db, `users/${email}/userFiles/folderData`);
  const userUsageTotalSumsRef = doc(
    db,
    `users/${email}/userUsage/userUsageTotalSums`
  );

  const folderDataSnap = await getDoc(folderDataRef);
  const userUsageTotalSumsSnap = await getDoc(userUsageTotalSumsRef);

  let totalTokensUsed = 0;
  let totalCreditsUsed = 0;
  let totalCreditsRemaining = 0;

  if (userUsageTotalSumsSnap.exists()) {
    const userUsageTotalSumsData = userUsageTotalSumsSnap.data();
    totalTokensUsed = userUsageTotalSumsData.userUsageTotalTokensSum || 0;
    totalCreditsUsed = userUsageTotalSumsData.userUsageTotalCreditsSum || 0;
    totalCreditsRemaining =
      userUsageTotalSumsData.userUsageTotalCreditsRemaining || 0;
  }

  return {
    folderData: folderDataSnap.exists() ? folderDataSnap.data() : null,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  };
}

// Processes and stores fetched data
function storeUserGetData(userData) {
  const {
    folderData,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  } = userData;

  const userFolderMakes = folderData ? folderData.userFolderMakes || "" : "";

  return {
    userFolderMakes,
    totalTokensUsed,
    totalCreditsUsed,
    totalCreditsRemaining,
  };
}

// ------------------------------
// UI DISPLAY FUNCTIONS
// ------------------------------

// Display Sign-In Button
export async function displaySignInButton() {
  const $userGetPlaceholder = $("#userGetPlaceholder");

  // Remove any existing userGetContainer to avoid duplicates
  $("#userGetContainer").remove();
  $("#userGetHr").remove();

  // Google Sign-In Button
  $userGetPlaceholder.append(
    $("<button>")
      .attr("id", "google-sign-in")
      .addClass("user-get-btn-google-sign-in")
  );

  setupGoogleSignIn();
}

// Display userGet data
export async function displayUserGetData(email) {
  const userEmail = email || "user@email.com"; // Default if no email provided
  const fetchedData = await fetchUserGetData(userEmail);
  const processedData = storeUserGetData(fetchedData);
  const userGetPlaceholder = $("#userGetPlaceholder");

  // Remove any existing userGetContainer to avoid duplicates
  $("#userGetContainer").remove();

  // Create the userGetContainer div dynamically and append it to mainContainer
  const $userGetContainer = $("<div>")
    .attr("id", "userGetContainer")
    .addClass("user-get-container")
    .css("display", "flex");

  userGetPlaceholder.append($userGetContainer);

  // User Email
  $userGetContainer.append(
    $("<span>").addClass("user-get-span").text(userEmail)
  );

  // Display tokens used and credits used
  $userGetContainer.append(
    $("<span>")
      .addClass("user-get-span")
      .append(
        $("<span>").text(`Credits Used: ${processedData.totalCreditsUsed}`),
        console.log(
          "Tokens Used: ",
          processedData.totalTokensUsed,
          "| Credits Used: ",
          processedData.totalCreditsUsed
        )
      )
  );

  // Install Extension Button
  $userGetContainer.append(
    $("<button>")
      .addClass("user-get-btn")
      .text("Install Extension")
      .on("click", function () {
        window.open(
          `https://chromewebstore.google.com/detail/autocv/micjohkmnpfkcnfoifijfdffpcajjiod`,
          "_blank"
        );
      })
  );

  // Logout Button
  $userGetContainer.append(
    $("<button>").attr("id", "logout").addClass("user-get-btn").text("Logout")
  );

  $("#userGetPlaceholder").append($("<hr>").attr("id", "userGetHr"));

  setupLogoutListener();
}

// ------------------------------
// AUTH STATE CHANGE HANDLER
// ------------------------------

const testEmail = getTestEmailFromURL();

if (testEmail) {
  console.log(`Test email detected: ${testEmail}`);
  displayUserGetData(testEmail); // Bypass auth state, display data for test email
} else {
  // Listen for auth state changes if no test email is provided
  onAuthStateChanged(auth, async (user) => {
    const email = user?.email || "user@email.com"; // Use a fallback email

    if (user) {
      console.log(`User: ${email}`);
      displayUserGetData(email);

      // Send message to content script
      setTimeout(() => {
        sendMessageToContentScript({
          type: "USER_EMAIL",
          email: email,
        });
      }, 3000);
    } else {
      // No user is logged in, display Sign-In Button
      console.log("No user is logged in.");
      displaySignInButton();
    }
  });
}
