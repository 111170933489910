import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
} from "firebase/firestore";
import { app } from "./firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import $ from "jquery";

import { setupDeleteMakeButtons } from "./dbDeleteMake";
import { setupMakeCoverLetterButtons } from "./dbMakeCoverLetter";

import googleDriveIcon from "../images/icon-google-drive.png";
import pdfIcon from "../images/icon-pdf.png";

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Helper function to get test email from URL
function getTestEmailFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("email");
}

// Fetches user makes from Firestore
export async function fetchUserMakes(email) {
  const userMakesRef = collection(db, `users/${email}/userMakes`);

  // Fetch folder data if available
  const userFilesDocRef = doc(db, `users/${email}/userFiles/folderData`);
  const userFilesDoc = await getDoc(userFilesDocRef);
  const userFolderMakes = userFilesDoc.exists()
    ? userFilesDoc.data().userFolderMakes
    : null;

  const q = query(userMakesRef); // Add any necessary queries or filters
  const querySnapshot = await getDocs(q);
  let userMakes = [];
  querySnapshot.forEach((doc) => {
    userMakes.push({ id: doc.id, ...doc.data() });
  });

  return { userMakes, userFolderMakes }; // Return both userMakes and userFolderMakes
}

// UI creation and display functions
export function createModuleContainer() {
  // Create the module container div
  $("#userMakesPlaceholder").append(
    $("<div>")
      .attr("id", "moduleContainer")
      .css("opacity", "100")
      .append(
        $("<h2>")
          .text("User Makes")
          .attr("title", "Show/Hide")
          .addClass("dashboard-module-header")
          .css("cursor", "pointer")
          .on("click", function () {
            $("#userMakesTable").toggle(); // Toggle table visibility
          })
      )
      .append(
        $("<div>").append(
          $("<table>")
            .attr("id", "userMakesTable")
            .addClass("user-makes-table")
            .append(
              $("<tr>")
                .append($("<th>").text("DATE"))
                .append($("<th>").text("JOB").css("text-align", "center"))
                .append($("<th>").text("GD"))
                .append($("<th>").text("PDF"))
                .append($("<th>").text("STATUS"))
                .append(
                  $("<th>")
                    .text("COVER LETTER")
                    .css("font-size", "11px")
                    .css("text-align", "center")
                )
                .append($("<th>").text("DELETE"))
            )
        )
      )
      .append($("<div>").attr("id", "userMakesContainer")) // Empty container
  );
}

export function displayUserMakes(userMakes, userFolderMakes) {
  const $table = $("#userMakesTable").css("display", "table");

  // Clear existing rows except for the header
  $table.find("tr:gt(0)").remove();

  if (userMakes.length === 0) {
    const $noMakesRow = $("<tr>").append(
      $("<td>")
        .attr("colspan", 7)
        .css("text-align", "center")
        .text("No makes found.")
    );
    $table.append($noMakesRow);
    return;
  }

  userMakes.sort((a, b) => b.makeDateTimeUNIX - a.makeDateTimeUNIX);

  let previousDate = null;

  userMakes.forEach((make) => {
    const currentDate = new Date(make.makeDateTimeUNIX);
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const dayKey = currentDate.toDateString();

    if (
      !previousDate ||
      currentDate.toDateString() !== previousDate.toDateString()
    ) {
      const $spacerRow = $("<tr>")
        .addClass("spacer-row")
        .append($("<td>").attr("colspan", 7).css("padding", "4px"));

      $table.append($spacerRow);

      const $dateRow = $("<tr>")
        .addClass("date-header")
        .attr("data-day", dayKey)
        .append(
          $("<td>")
            .attr("colspan", 7)
            .attr("title", "Show/Hide")
            .addClass("date-header")
            .text(formattedDate)
        );

      $table.append($dateRow);
    }

    previousDate = currentDate;

    const $row = $("<tr>").addClass("data-row").attr("data-day", dayKey);

    $row.append($("<td>").text(currentDate.toLocaleDateString()));

    $row.append(
      $("<td>")
        .addClass("track-app-td-job")
        .css("text-align", "left")
        .append(
          $("<a>")
            .attr({ href: make.makeJobPostURL, target: "_blank" })
            .text(make.makeTitle)
        )
    );

    $row.append(
      $("<td>").append(
        $("<a>")
          .attr({
            href: `https://drive.google.com/drive/folders/${make.makeFileIDMakeFolder}`,
            target: "_blank",
          })
          .append(
            $("<img>")
              .attr("src", googleDriveIcon)
              .attr("alt", "Google Drive Icon")
              .addClass("icon")
          )
      )
    );

    $row.append(
      $("<td>").append(
        $("<a>")
          .attr({
            href: `https://drive.google.com/file/d/${make.makeFileIDMakeResumePDF}`,
            target: "_blank",
          })
          .append(
            $("<img>")
              .attr("src", pdfIcon)
              .attr("alt", "PDF Icon")
              .addClass("icon")
          )
      )
    );

    $row.append($("<td>").text(make.makeStatus));

    $row.append(
      $("<td>").append(
        $("<button>")
          .text("Make")
          .addClass("user-makes-btn make-cl-btn")
          .attr("data-doc-id", make.id)
      )
    );

    $row.append(
      $("<td>").append(
        $("<button>")
          .text("Delete")
          .addClass("user-makes-btn delete-btn")
          .attr("data-doc-id", make.id)
      )
    );

    $table.append($row);
  });

  const $allUserMakesBtn = $("<button>")
    .addClass("user-makes-btn-all")
    .append(
      $("<span>").addClass("user-makes-btn-all-span").text("All User Makes")
    )
    .append(
      $("<span>")
        .addClass("user-makes-btn-all-span")
        .append(
          $("<img>")
            .attr("src", googleDriveIcon)
            .attr("alt", "Google Drive Icon")
            .addClass("icon")
        )
    )
    .on("click", function () {
      if (userFolderMakes) {
        window.open(
          `https://drive.google.com/drive/folders/${userFolderMakes}`,
          "_blank"
        );
      } else {
        alert("No folder found for user makes.");
      }
    });

  $("#userMakesPlaceholder").append($allUserMakesBtn);

  setupMakeCoverLetterButtons();
  setupDeleteMakeButtons();

  $(".date-header").on("click", function () {
    const day = $(this).data("day");
    $(`.data-row[data-day='${day}']`).toggle();
  });
}

// Updated onAuthStateChanged to handle test email from URL
onAuthStateChanged(auth, async (user) => {
  const testEmail = getTestEmailFromURL();
  const moduleContainer = document.getElementById("userMakesPlaceholder");

  if (testEmail) {
    console.log(`Test email detected: ${testEmail}`);
    const { userMakes, userFolderMakes } = await fetchUserMakes(testEmail);
    createModuleContainer();
    displayUserMakes(userMakes, userFolderMakes);
    return; // Stop further execution if test email is found
  }

  if (user) {
    console.log(`Firebase user logged in: ${user.email}`);
    const { userMakes, userFolderMakes } = await fetchUserMakes(user.email);
    createModuleContainer();
    displayUserMakes(userMakes, userFolderMakes);
  } else {
    moduleContainer.style.display = "none";
    console.log("No user is logged in.");
  }
});
