// moduleDashboard.js

import "./debugDisplay";
import "./moduleUser";
import "./moduleUserMakes";
import "./moduleUserBaseResumes";
// import "./moduleSetupResume";

// Function to get the test email from the URL
function getTestEmailFromURL() {
  const urlParams = new URLSearchParams(window.location.search);
  const testEmail = urlParams.get("email"); // Extract the email parameter from the URL
  return testEmail;
}

// Pass the email to the relevant modules if it exists
document.addEventListener("DOMContentLoaded", () => {
  const testEmail = getTestEmailFromURL();

  if (testEmail) {
    // Use the test email across modules
    console.log(`Loading data for test email: ${testEmail}`);
    import("./moduleUserMakes.js").then((module) => {
      module
        .fetchUserMakes(testEmail)
        .then(({ userMakes, userFolderMakes }) => {
          module.createModuleContainer();
          module.displayUserMakes(userMakes, userFolderMakes);
        });
    });

    import("./moduleUserBaseResumes.js").then((module) => {
      module.fetchUserBaseResumes(testEmail).then((userBaseResumes) => {
        module.createModuleContainerBaseResumes();
        module.displayUserBaseResumes(userBaseResumes);
      });
    });
  } else {
    console.log(
      "No test email provided, proceeding with authenticated user flow."
    );
  }
});
